.personal-statement-col {
    justify-content: center;
}

.ps-title {
    padding-left: 250px;
}

.fancy {
    font-style: italic;
}